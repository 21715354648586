import industrial from "../../images/productsImages/submersibleImages/industrial.jpg";
import residential from "../../images/productsImages/submersibleImages/residencial.jpg";
import residentialIcon from "../../images/productsImages/surfaceImages/residencialIcon.svg";
import industrialIcon from "../../images/productsImages/surfaceImages/industrialIcon.svg";

const fe = "Franklin Electric";

const surIndustrialList = [
  {
    _id: "surcatind001",
    title: "Bombas Superficie",
    subcategory: [
      { name: "Autocebantes", _id: "surcatindbomaut001" },
      { name: "Caracol", _id: "surcatindbomcar001" },
      { name: "Carscasa Partida", _id: "surcatindbomcap001" },
      { name: "Multietapa", _id: "surcatindbommul001" },
      { name: "Tipo ANSI", _id: "surcatindbomtip001" },
    ],
  },
  {
    _id: "surcatind002",
    title: "Motores Superficie",
    subcategory: [
      { name: "Baldor", _id: "surcatindmotbal001" },
      { name: "General Electric", _id: "surcatindmotLGl001" },
    ],
  },
];
const surResidencialList = [
  {
    _id: "surcatres001",
    title: "Autocebantes",
  },
  {
    _id: "surcatres002",
    title: "Caracol",
  },
  {
    _id: "surcatres003",
    title: "Combustible",
  },
  {
    _id: "surcatres004",
    title: "Jet",
    subcategory: [
      { name: fe, _id: "surcatresjetfe001" },
      { name: "Pearl", _id: "surcatresjetpearl001" },
    ],
  },
  {
    _id: "surcatres005",
    title: "Multietapa",
    subcategory: [
      { name: fe, _id: "surcatresmulfe001" },
      { name: "Little Giant", _id: "surcatresmullg001" },
      { name: "Pearl", _id: "surcatresmulpearl001" },
      { name: "Speroni", _id: "surcatresmulsperonil001" },
    ],
  },
  {
    _id: "surcatres006",
    title: "Periférica",
    subcategory: [
      { name: "Little Giant", _id: "surcatresperlg001" },
      { name: "Pearl", _id: "surcatresperpearl001" },
    ],
  },
  {
    _id: "surcatres007",
    title: "Recirculadora",
  },
];

const surfaceList = [
  {
    _id: "sur001",
    image: industrial,
    title: "Industrial",
    link: "/productos/bombassuperficie/industrial",
    list: surIndustrialList,
    path: "industrial",
    icon: industrialIcon,
  },
  {
    _id: "sur002",
    image: residential,
    title: "Residencial",
    link: "/productos/bombassuperficie/residencial",
    list: surResidencialList,
    path: "residencial",
    icon: residentialIcon,
  },
];

export { surfaceList };
