import submersibleproduct1 from "../../../images/productsImages/submersibleImages/products/accesories/BOSHART/CHECKPASCON.png";
import submersibleproduct2 from "../../../images/productsImages/submersibleImages/products/accesories/BOSHART/SELLOSDEPOZO.png";
import submersibleproduct3 from "../../../images/productsImages/submersibleImages/products/accesories/FLOMATIC/checkvertical.jpg";
import submersibleproduct4 from "../../../images/productsImages/submersibleImages/products/accesories/FRANKLINELECTRIC/CAJASDEARRANQUE.png";
import submersibleproduct5 from "../../../images/productsImages/submersibleImages/products/accesories/FRANKLINELECTRIC/PUMPTEC.png";
import submersibleproduct6 from "../../../images/productsImages/submersibleImages/products/accesories/FRANKLINELECTRIC/SUBMONITOR.jpg";
import submersibleproduct7 from "../../../images/productsImages/submersibleImages/products/accesories/KALAS/cable.JPG";
import submersibleproduct8 from "../../../images/productsImages/submersibleImages/products/accesories/PEARL/CAJASDEARRANQUE.png";
import submersibleproduct9 from "../../../images/productsImages/submersibleImages/products/accesories/SPERONI/controlbox.jpg";
import pdf3 from "../../../pdf/submersible/accessories/checkVertical.pdf";
import pdf4 from "../../../pdf/submersible/accessories/cajasdecontrolFE.pdf";
import pdf5 from "../../../pdf/submersible/accessories/pumptec.pdf";
import pdf6 from "../../../pdf/submersible/accessories/SUBMONITORCONNECT.pdf";
import pdf7 from "../../../pdf/submersible/accessories/KalasSumergible.pdf";
import pdf9 from "../../../pdf/submersible/accessories/controlbox.pdf";

const submersibleAccessoriesProductList = [
  {
    _id: "subcatacc001a",
    image: submersibleproduct1,
    title: "Check pascon",
    code: "",
    category: "Boshart",
    brand: "Boshart",
    subcategory: "Check pascon",
    downLoad: "none",
  },
  {
    _id: "subcatacc001b",
    image: submersibleproduct2,
    title: "Sello de pozo",
    code: "",
    category: "Boshart",
    brand: "Boshart",
    subcategory: "Sellos de pozo",
    downLoad: "none",
  },
  {
    _id: "subcatacc002a",
    image: submersibleproduct3,
    title: "Check vertical",
    code: "",
    category: "Flomatic",
    brand: "Flomatic",
    subcategory: "Check Vertical",
    downLoad: pdf3,
  },
  {
    _id: "subcatacc003a",
    image: submersibleproduct4,
    title: "Cajas de arranque",
    code: "",
    category: "Franklin Electric",
    brand: "Franklin Electric",
    subcategory: "Cajas de arranque",
    downLoad: pdf4,
  },
  {
    _id: "subcatacc003b",
    image: submersibleproduct5,
    title: "Protección Pumptec",
    code: "",
    category: "Franklin Electric",
    brand: "Franklin Electric",
    subcategory: "Pumptec",
    downLoad: pdf5,
  },
  {
    _id: "subcatacc003c",
    image: submersibleproduct6,
    title: "Submonitor Connect",
    code: "",
    category: "Franklin Electric",
    brand: "Franklin Electric",
    subcategory: "Submonitor",
    downLoad: pdf6,
  },
  {
    _id: "subcatacc004a",
    image: submersibleproduct7,
    title: "Cable de bomba sumergible",
    code: "",
    category: "Kalas",
    brand: "Kalas",
    subcategory: "none",
    downLoad: pdf7,
  },
  {
    _id: "subcatacc005a",
    image: submersibleproduct8,
    title: "Cajas de arranque",
    code: "",
    category: "Pearl",
    brand: "Pearl",
    subcategory: "none",
    downLoad: "none",
  },
  {
    _id: "subcatacc006a",
    image: submersibleproduct9,
    title: "Cajas de arranque",
    code: "",
    category: "Speroni",
    brand: "Speroni",
    subcategory: "none",
    downLoad: pdf9,
  },
];

export default submersibleAccessoriesProductList;
