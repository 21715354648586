import accessories from "../../images/productsImages/submersibleImages/submersible.jpg";
import solarbomb from "../../images/productsImages/submersibleImages/panel.jpg";
import industrial from "../../images/productsImages/submersibleImages/industrial.jpg";
import residential from "../../images/productsImages/submersibleImages/residencial.jpg";
import accessoriesIcon from "../../images/productsImages/submersibleImages/accessoriesIcon.svg";
import solarbombIcon from "../../images/productsImages/submersibleImages/solarbombIcon.svg";
import industrialIcon from "../../images/productsImages/submersibleImages/industrialIcon.svg";
import residentialIcon from "../../images/productsImages/submersibleImages/residentialIcon.svg";

const fe = "Franklin Electric";

const accessoriesList = [
  {
    _id: "subcatacc001",
    title: "Boshart",
    subcategory: [
      { name: "Check pascon", _id: "subcataccbos001" },
      { name: "Sellos de pozo", _id: "subcataccbos002" },
    ],
  },
  {
    _id: "subcatacc002",
    title: "Flomatic",
  },
  {
    _id: "subcatacc003",
    title: fe,
    subcategory: [
      { name: "Cajas de arranque", _id: "subcataccfra001" },
      { name: "Pumptec", _id: "subcataccfra002" },
      { name: "Submonitor", _id: "subcataccfra003" },
    ],
  },
  {
    _id: "subcatacc004",
    title: "Kalas",
  },
  {
    _id: "subcatacc005",
    title: "Speroni",
  },
  {
    _id: "subcatacc006",
    title: "Pearl",
  },
];

const solarBombList = [
  {
    _id: "subcatsol001",
    title: fe,
  },
];

const sumIndustrialList = [
  {
    _id: "subcatind001",
    title: "Aguas Negras",
    subcategory: [
      { name: "BGP", _id: "subcatindagubgp001" },
      { name: fe, _id: "subcatindagufe001" },
      { name: "Little Giant", _id: "subcatindagulg001" },
    ],
  },
  {
    _id: "subcatind002",
    title: "Alta Eficiencia",
  },
  {
    _id: "subcatind003",
    title: "Bombas Sumergibles",
    subcategory: [
      { name: fe, _id: "subcatindbomfe001" },
      { name: "Pearl", _id: "subcatindbompearl001" },
      { name: "Speroni", _id: "subcatindbomsperoni001" },
    ],
  },
  {
    _id: "subcatind004",
    title: "Motores Sumergibles",
    subcategory: [
      { name: fe, _id: "subcatindmotfe001" },
      { name: "Pearl", _id: "subcatindmotpearl001" },
      { name: "Speroni", _id: "subcatindmotsperonil001" },
    ],
  },
];

const sumResidencialList = [
  {
    _id: "subcatres001",
    title: "Bombas Fuente y Achique",
    subcategory: [
      { name: "Little Giant", _id: "subcatresbomlg001" },
      { name: "Pearl", _id: "subcatresbompearl001" },
      { name: "Speroni", _id: "subcatresbomsperoni001" },
    ],
  },
  {
    _id: "subcatres002",
    title: "Bombas Sumergibles",
    subcategory: [
      { name: fe, _id: "subcatresbomsfe001" },
      { name: "Pearl", _id: "subcatresbomspearl001" },
      { name: "Speroni", _id: "subcatresbomssperoni001" },
    ],
  },
  {
    _id: "subcatind003",
    title: "Motores Sumergibles",
    subcategory: [
      { name: fe, _id: "subcatresmotfra001" },
      { name: "Pearl", _id: "subcatresmotpearl001" },
      { name: "Speroni", _id: "subcatresmotsperoni001" },
    ],
  },
];

const submersibleList = [
  {
    _id: "sum001",
    image: accessories,
    title: "Accesorios para equipos sumergibles",
    link: "/productos/bombassumergibles/accessorios",
    list: accessoriesList,
    path: "accessorios",
    icon: accessoriesIcon,
  },
  {
    _id: "sum002",
    image: solarbomb,
    title: "Bombeo Solar",
    link: "/productos/bombassumergibles/bombassolares",
    list: solarBombList,
    path: "bombassolares",
    icon: solarbombIcon,
  },
  {
    _id: "sum003",
    image: industrial,
    title: "Industrial",
    link: "/productos/bombassumergibles/industrial",
    list: sumIndustrialList,
    path: "industrial",
    icon: industrialIcon,
  },
  {
    _id: "sum004",
    image: residential,
    title: "Residencial",
    link: "/productos/bombassumergibles/residencial",
    list: sumResidencialList,
    path: "residencial",
    icon: residentialIcon,
  },
];

export { submersibleList };
