import submersibleproduct1 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/LG/LGmsp.jpg";
import submersibleproduct2 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/LG/PESPHOTO.jpg";
import submersibleproduct3 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/LG/SERIE6.jpg";
import submersibleproduct4 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/LG/SERIE10.jpg";
import submersibleproduct5 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/LG/SERIELUP.jpg";
import submersibleproduct6 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/PEARL/SLP.png";
import submersibleproduct7 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/PEARL/PEARL-TECHDATA-SSP.png";
import submersibleproduct8 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/SPERONI/SV.jpg";
import submersibleproduct9 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/SPERONI/SVN.jpg";
import submersibleproduct10 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/SPERONI/X1.jpg";
import submersibleproduct11 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/SPERONI/XD.jpg";
import submersibleproduct12 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/FE/C1.jpg";
import submersibleproduct13 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/FE/HI-CAP.jpg";
import submersibleproduct14 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/FE/TRI-SEAL.jpg";
import submersibleproduct15 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/PEARL/MINISUB.jpg";
import submersibleproduct16 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/PEARL/PWP-01.png";
import submersibleproduct17 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/SPERONI/SPM.jpg";
import submersibleproduct18 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/SPERONI/ST.jpg";
import submersibleproduct19 from "../../../images/productsImages/submersibleImages/products/Residencial/MotoresSumergibles/FRANKLINELECTRIC/ALTOeMPUJE.jpg";
import submersibleproduct20 from "../../../images/productsImages/submersibleImages/products/Residencial/MotoresSumergibles/FRANKLINELECTRIC/SUPERINOX.jpg";
import submersibleproduct21 from "../../../images/productsImages/submersibleImages/products/Residencial/MotoresSumergibles/PEARL/4MOPPearl.jpg";
import submersibleproduct22 from "../../../images/productsImages/submersibleImages/products/Residencial/MotoresSumergibles/SPERONI/motoraceite.jpg";
import submersibleproduct23 from "../../../images/productsImages/submersibleImages/products/Residencial/MotoresSumergibles/SPERONI/motorencapsulado.jpg";

import pdf1 from "../../../pdf/submersible/residential/5msp.pdf";
import pdf2 from "../../../pdf/submersible/residential/BombasFuentesPES.pdf";
import pdf3 from "../../../pdf/submersible/residential/Series6.pdf";
import pdf4 from "../../../pdf/submersible/residential/serie10.pdf";
import pdf5 from "../../../pdf/submersible/residential/Serie-LUP.pdf";
import pdf6 from "../../../pdf/submersible/residential/SLP.pdf";
import pdf7 from "../../../pdf/submersible/residential/SSP.pdf";
import pdf8 from "../../../pdf/submersible/residential/SerieSV.pdf";
import pdf9 from "../../../pdf/submersible/residential/Seriesvn.pdf";
import pdf10 from "../../../pdf/submersible/residential/X1.pdf";
import pdf11 from "../../../pdf/submersible/residential/XD.pdf";
import pdf12 from "../../../pdf/submersible/residential/serie-c1.pdf";
import pdf13 from "../../../pdf/submersible/residential/HI-CAP.pdf";
import pdf14 from "../../../pdf/submersible/residential/Tri-Seal.pdf";
import pdf15 from "../../../pdf/submersible/residential/mini-sub.pdf";
import pdf16 from "../../../pdf/submersible/residential/PWP.pdf";
import pdf17 from "../../../pdf/submersible/residential/SPM.pdf";
import pdf18 from "../../../pdf/submersible/residential/serieST.pdf";
import pdf19 from "../../../pdf/submersible/residential/submersible-motor-4in.pdf";
import pdf20 from "../../../pdf/submersible/residential/submersible-motor.pdf";
import pdf21 from "../../../pdf/submersible/residential/4mop.pdf";
import pdf22 from "../../../pdf/submersible/residential/motoraceite.pdf";
import pdf23 from "../../../pdf/submersible/residential/motorencapsulado4.pdf";

const fe = "Franklin Electric";

const submersibleResidentialProductList = [
  {
    _id: "subcatresbomlg001a",
    image: submersibleproduct1,
    title: "Bomba sumergible utilitaria serie 5-MSP",
    code: "5-MSP",
    category: "Bombas Fuente y Achique",
    brand: "Little Giant",
    subcategory: "Little Giant",
    downLoad: pdf1,
  },
  {
    _id: "subcatresbomlg001b",
    image: submersibleproduct2,
    title: "Bombas sumergibles de acople indirecto PES",
    code: "PES",
    category: "Bombas Fuente y Achique",
    brand: "Little Giant",
    subcategory: "Little Giant",
    downLoad: pdf2,
  },
  {
    _id: "subcatresbomlg001c",
    image: submersibleproduct3,
    title: `Bomba sumergible Serie 6"`,
    code: "",
    category: "Bombas Fuente y Achique",
    brand: "Little Giant",
    subcategory: "Little Giant",
    downLoad: pdf3,
  },
  {
    _id: "subcatresbomlg001d",
    image: submersibleproduct4,
    title: `Bomba sumergible Serie 10"`,
    code: "",
    category: "Bombas Fuente y Achique",
    brand: "Little Giant",
    subcategory: "Little Giant",
    downLoad: pdf4,
  },
  {
    _id: "subcatresbomlg001e",
    image: submersibleproduct5,
    title: "Bomba sumergible utilitaria serie LUP",
    code: "",
    category: "Bombas Fuente y Achique",
    brand: "Little Giant",
    subcategory: "Little Giant",
    downLoad: pdf5,
  },
  {
    _id: "subcatresbomPEARL001a",
    image: submersibleproduct6,
    title: "Bomba sumergible para aguas limpias serie SLP",
    code: "SLP",
    category: "Bombas Fuente y Achique",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf6,
  },
  {
    _id: "subcatresbomPEARL001b",
    image: submersibleproduct7,
    title: "Bombas sumergibles para aguas negras SSP",
    code: "SSP",
    category: "Bombas Fuente y Achique",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf7,
  },
  {
    _id: "subcatresbomSPERONI001a",
    image: submersibleproduct8,
    title: "Bombas sumergibles para aguas negras SV",
    code: "",
    category: "Bombas Fuente y Achique",
    brand: "Speroni",
    subcategory: "Speroni",
    downLoad: pdf8,
  },
  {
    _id: "subcatresbomSPERONI001b",
    image: submersibleproduct9,
    title: "Bombas sumergibles para aguas negras SVN",
    code: "",
    category: "Bombas Fuente y Achique",
    brand: "Speroni",
    subcategory: "Speroni",
    downLoad: pdf9,
  },
  {
    _id: "subcatresbomSPERONI001c",
    image: submersibleproduct10,
    title: "Bombas sumergibles serie X1",
    code: "",
    category: "Bombas Fuente y Achique",
    brand: "Speroni",
    subcategory: "Speroni",
    downLoad: pdf10,
  },
  {
    _id: "subcatresbomSPERONI001d",
    image: submersibleproduct11,
    title: "Bombas sumergibles serie XD",
    code: "",
    category: "Bombas Fuente y Achique",
    brand: "Speroni",
    subcategory: "Speroni",
    downLoad: pdf11,
  },

  {
    _id: "subcatresbomsumfe001a",
    image: submersibleproduct12,
    title: "Bombas de cisterna serie C1",
    code: "",
    category: "Bombas Sumergibles",
    brand: fe,
    subcategory: fe,
    downLoad: pdf12,
  },
  {
    _id: "subcatresbomsumfe001b",
    image: submersibleproduct13,
    title: `Bombas sumergibles Tri-Seal High Capacity 4"`,
    code: "HI-CAP",
    category: "Bombas Sumergibles",
    brand: fe,
    subcategory: fe,
    downLoad: pdf13,
  },
  {
    _id: "subcatresbomsumfe001c",
    image: submersibleproduct14,
    title: "Bombas sumergibles Tri-Seal",
    code: "TRI-SEAL",
    category: "Bombas Sumergibles",
    brand: fe,
    subcategory: fe,
    downLoad: pdf14,
  },
  {
    _id: "subcatresbomsumpearl001a",
    image: submersibleproduct15,
    title: "Bombas sumergibles MiniSub",
    code: "MINISUB",
    category: "Bombas Sumergibles",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf15,
  },
  {
    _id: "subcatresbomsumpearl001b",
    image: submersibleproduct16,
    title: "Bomba sumergible 4PWP",
    code: "4PWP",
    category: "Bombas Sumergibles",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf16,
  },

  {
    _id: "subcatresbomsumsperonil001f",
    image: submersibleproduct17,
    title: "Bomba sumergible serie SPM",
    code: "SPM",
    category: "Bombas Sumergibles",
    brand: "Speroni",
    subcategory: "Speroni",
    downLoad: pdf17,
  },
  {
    _id: "subcatresbomsumsperonil001g",
    image: submersibleproduct18,
    title: "Bomba sumergible serie ST",
    code: "ST",
    category: "Bombas Sumergibles",
    brand: "Speroni",
    subcategory: "Speroni",
    downLoad: pdf18,
  },
  {
    _id: "subcatresmotfra001a",
    image: submersibleproduct19,
    title: `Motor sumergible de alto empuje 4"`,
    code: "",
    category: "Motores Sumergibles",
    brand: fe,
    subcategory: fe,
    downLoad: pdf19,
  },
  {
    _id: "subcatresmotfra001b",
    image: submersibleproduct20,
    title: "Motor sumergible de super inox",
    code: "",
    category: "Motores Sumergibles",
    brand: fe,
    subcategory: fe,
    downLoad: pdf20,
  },
  {
    _id: "subcatresmotpearl001a",
    image: submersibleproduct21,
    title: "Motor sumergible 4MOP",
    code: "4MOP",
    category: "Motores Sumergibles",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf21,
  },
  {
    _id: "subcatresmotsperonil001a",
    image: submersibleproduct22,
    title: "Motor en aceite",
    code: "4MOP",
    category: "Motores Sumergibles",
    brand: "Speroni",
    subcategory: "Speroni",
    downLoad: pdf22,
  },
  {
    _id: "subcatresmotsperonil001b",
    image: submersibleproduct23,
    title: `Motor encapsulado en resina 4"`,
    code: "4MOP",
    category: "Motores Sumergibles",
    brand: "Speroni",
    subcategory: "Speroni",
    downLoad: pdf23,
  },
];

export default submersibleResidentialProductList;
