import surfaceproduct1 from "../../../images/productsImages/surfaceImages/Residencial/Autocebantes/FBSEF.jpg";
import surfaceproduct2 from "../../../images/productsImages/surfaceImages/Residencial/Autocebantes/TURFBOSS.jpg";
import surfaceproduct3 from "../../../images/productsImages/surfaceImages/Residencial/Caracol/CEPPearlCaracol.jpg";
import surfaceproduct4 from "../../../images/productsImages/surfaceImages/Residencial/Combustible/EGP10.png";
import surfaceproduct5 from "../../../images/productsImages/surfaceImages/Residencial/Combustible/EGP20.png";
import surfaceproduct6 from "../../../images/productsImages/surfaceImages/Residencial/Combustible/EGP30.jpg";
import surfaceproduct7 from "../../../images/productsImages/surfaceImages/Residencial/Jet/FE/SerieLJP.jpg";
import surfaceproduct8 from "../../../images/productsImages/surfaceImages/Residencial/Jet/FE/VERSAJETFVJ.jpg";
import surfaceproduct9 from "../../../images/productsImages/surfaceImages/Residencial/Jet/PEARL/JCP.png";
import surfaceproduct10 from "../../../images/productsImages/surfaceImages/Residencial/Jet/PEARL/JSP.jpg";
import surfaceproduct11 from "../../../images/productsImages/surfaceImages/Residencial/Multietapa/FE/SerieBT4.jpg";
import surfaceproduct12 from "../../../images/productsImages/surfaceImages/Residencial/Multietapa/FE/SerieMH.png";
import surfaceproduct13 from "../../../images/productsImages/surfaceImages/Residencial/Multietapa/Little Giant/SerieLHLittleGiant.jpg";
import surfaceproduct14 from "../../../images/productsImages/surfaceImages/Residencial/Multietapa/Pearl/C2PPearl.png";
import surfaceproduct15 from "../../../images/productsImages/surfaceImages/Residencial/Multietapa/speroni/superficieCB.jpg";
import surfaceproduct16 from "../../../images/productsImages/surfaceImages/Residencial/Multietapa/speroni/HBI.jpg";
import surfaceproduct17 from "../../../images/productsImages/surfaceImages/Residencial/Periférica/LittleGiantSeriePG.png";
import surfaceproduct18 from "../../../images/productsImages/surfaceImages/Residencial/Periférica/PEPPearl.png";
import surfaceproduct19 from "../../../images/productsImages/surfaceImages/Residencial/Recirculadora/RecirculadorasAHW.jpg";
import pdf1 from "../../../pdf/surface/residential/FBSE.pdf";
import pdf2 from "../../../pdf/surface/residential/turfboss.pdf";
import pdf3 from "../../../pdf/surface/residential/CEP.pdf";
import pdf4 from "../../../pdf/surface/residential/BombaGasolinaEGP10.pdf";
import pdf5y6 from "../../../pdf/surface/residential/BombaGasolinaEGP20EGP30.pdf";
import pdf7 from "../../../pdf/surface/residential/LJP.pdf";
import pdf8 from "../../../pdf/surface/residential/FVJ.pdf";
import pdf9 from "../../../pdf/surface/residential/JCP.pdf";
import pdf10 from "../../../pdf/surface/residential/JSP.pdf";
import pdf11 from "../../../pdf/surface/residential/BT4.pdf";
import pdf12 from "../../../pdf/surface/residential/SerieMH.pdf";
import pdf13 from "../../../pdf/surface/residential/LH.pdf";
import pdf14 from "../../../pdf/surface/residential/C2P.pdf";
import pdf15 from "../../../pdf/surface/residential/CB-CBI.pdf";
import pdf16 from "../../../pdf/surface/residential/HBI-HBN.pdf";
import pdf17 from "../../../pdf/surface/residential/Serie-PG.pdf";
import pdf18 from "../../../pdf/surface/residential/PEP.pdf";
import pdf19 from "../../../pdf/surface/residential/fichaAHW.pdf";

const fe = "Franklin Electric";

const surfaceResidentialProductList = [
  {
    _id: "surcatresauto001a",
    image: surfaceproduct1,
    title: "Bombas Efluentes Autocebantes Serie FBSE",
    code: "",
    category: "Autocebantes",
    brand: fe,
    subcategory: "",
    downLoad: pdf1,
  },
  {
    _id: "surcatresauto001b",
    image: surfaceproduct2,
    title: "Bombas Aspersoras TURF BOSS",
    code: "",
    category: "Autocebantes",
    brand: fe,
    subcategory: "",
    downLoad: pdf2,
  },
  {
    _id: "surcatrescar001a",
    image: surfaceproduct3,
    title: "Electrobombas Centrífugas CEP",
    code: "",
    category: "Caracol",
    brand: "Pearl",
    subcategory: "",
    downLoad: pdf3,
  },
  {
    _id: "surcatrescom001a",
    image: surfaceproduct4,
    title: "Bomba de Gasolina EGP10",
    code: "",
    category: "Combustible",
    brand: "Aquastrong",
    subcategory: "",
    downLoad: pdf4,
  },
  {
    _id: "surcatrescom001b",
    image: surfaceproduct5,
    title: "Bomba de Gasolina EGP20",
    code: "",
    category: "Combustible",
    brand: "Aquastrong",
    subcategory: "",
    downLoad: pdf5y6,
  },
  {
    _id: "surcatrescom001c",
    image: surfaceproduct6,
    title: "Bomba de Gasolina EGP30",
    code: "",
    category: "Combustible",
    brand: "Aquastrong",
    subcategory: "",
    downLoad: pdf5y6,
  },
  {
    _id: "surcatresjetfe001a",
    image: surfaceproduct7,
    title: "Bomba JET Serie LJP",
    code: "",
    category: "Jet",
    brand: fe,
    subcategory: fe,
    downLoad: pdf7,
  },
  {
    _id: "surcatresjetfe001b",
    image: surfaceproduct8,
    title: "Bombas JET Serie VERSAJET",
    code: "",
    category: "Jet",
    brand: fe,
    subcategory: fe,
    downLoad: pdf8,
  },
  {
    _id: "surcatresjetpearl001a",
    image: surfaceproduct9,
    title: "Electrobombas Jet Autocebantes en Hierro JCP",
    code: "",
    category: "Jet",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf9,
  },
  {
    _id: "surcatresjetpearl001b",
    image: surfaceproduct10,
    title: "Electrobombas Jet Autocebantes JSP",
    code: "",
    category: "Jet",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf10,
  },
  {
    _id: "surcatresmulfe001b",
    image: surfaceproduct11,
    title: "Bombas Multietapa Serie BT4",
    code: "",
    category: "Multietapa",
    brand: fe,
    subcategory: fe,
    downLoad: pdf11,
  },
  {
    _id: "surcatresmulfe001c",
    image: surfaceproduct12,
    title: "Motor IEC Serie MH",
    code: "",
    category: "Multietapa",
    brand: fe,
    subcategory: fe,
    downLoad: pdf12,
  },
  {
    _id: "surcatresmullg001b",
    image: surfaceproduct13,
    title: "Bomba Horizontal Serie LH",
    code: "",
    category: "Multietapa",
    brand: "Little Giant",
    subcategory: "Little Giant",
    downLoad: pdf13,
  },
  {
    _id: "surcatresmulpearl001b",
    image: surfaceproduct14,
    title: "Electrobombas Centrífugas de Doble Impulsor C2P",
    code: "",
    category: "Multietapa",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf14,
  },
  {
    _id: "surcatresmulsperonil001a",
    image: surfaceproduct15,
    title: "Electrobomba Horizontal serie CB, CBI ",
    code: "",
    category: "Multietapa",
    brand: "Speroni",
    subcategory: "Speroni",
    downLoad: pdf15,
  },
  {
    _id: "surcatresmulsperoni001b",
    image: surfaceproduct16,
    title: "Electrobomba Horizontal serie HBI, HBN",
    code: "",
    category: "Multietapa",
    brand: "Speroni",
    subcategory: "Speroni",
    downLoad: pdf16,
  },
  {
    _id: "surcatresperlg001a",
    image: surfaceproduct17,
    title: "Bomba Periférica Serie PG",
    code: "",
    category: "Periférica",
    brand: "Little Giant",
    subcategory: "Little Giant",
    downLoad: pdf17,
  },
  {
    _id: "surcatresperpearl001a",
    image: surfaceproduct18,
    title: "Electrobombas Periféricas PEP",
    code: "",
    category: "Periférica",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf18,
  },
  {
    _id: "surcatresrecirsperoni001a",
    image: surfaceproduct19,
    title: "Bomba recirculadora de agua caliente AHW",
    code: "",
    category: "Recirculadora",
    brand: "Speroni",
    subcategory: "Speroni",
    downLoad: pdf19,
  },
];

export default surfaceResidentialProductList;
