import React, { useState } from "react";
import { surfaceList } from "../../../Lists/products/surfaceList";
import brandsList from "../../../Lists/brands";
import Category from "../../../components/UI/Category";
import classes from "./surfaceProducts.module.css";
import Subcategory from "../../../components/Subcategorymenu";
import { useParams } from "react-router-dom";
import surfaceResidentialProductList from "../../../Lists/products/surfaceProducts/surfaceResidentialList";
import surfaceIndustrialProductList from "../../../Lists/products/surfaceProducts/surfaceIndustrialList";
import franklinLogo from "../../../images/brands/brands_FRANKLIN.svg";

const SurfaceProducts = () => {
  const newArray = [];

  const params = useParams();
  const listCategory = surfaceList.find(
    (category) => category.path === params.surfaceId
  );
  const FranklinElectricBrand = {
    image: franklinLogo,
    alt: "Franklin Electric",
  };

  const findListHandle = (path) => {
    if (path === "industrial") {
      return surfaceIndustrialProductList;
    }
    if (path === "residencial") {
      return surfaceResidentialProductList;
    }
  };

  const originalList = findListHandle(listCategory.path);

  const [displayedList, setdisplayedList] = useState(
    findListHandle(listCategory.path)
  );
  const [displayedBrand, setdisplayedBrand] = useState("none");

  const onListChange = (category) => {
    newArray.length = 0;
    originalList.map((element) => sort(element, category));
    setdisplayedList(newArray);
    setdisplayedBrand(findBrand(category, listCategory.path));
  };

  const sort = (element, category) => {
    if (element.category === category) {
      newArray.push(element);
    }
  };

  const onsubListChange = (category, secondCategory) => {
    newArray.length = 0;
    originalList.map((element) =>
      secondsort(element, category, secondCategory)
    );

    setdisplayedList(newArray);
    setdisplayedBrand(findBrand(secondCategory));
  };
  const secondsort = (element, category, secondCategory) => {
    if (
      element.category === category &&
      element.subcategory === secondCategory
    ) {
      newArray.push(element);
    }
  };
  const findBrand = (brand, cate) => {
    if (brand === "Autocebantes") {
      return FranklinElectricBrand;
    }
    if (brand === "Carscasa Partida") {
      return brandsList[13];
    }
    if (brand === "General Electric") {
      return brandsList[15];
    }
    if (brand === "Baldor") {
      return brandsList[17];
    }
    if (brand === "Combustible") {
      return brandsList[8];
    }
    if (brand === "Franklin Electric") {
      return FranklinElectricBrand;
    }
    if (brand === "Pearl") {
      return brandsList[18];
    }
    if (brand === "Speroni") {
      return brandsList[0];
    }
    if (brand === "Little Giant") {
      return brandsList[5];
    }
    if (cate === "residencial" && brand === "Caracol") {
      return brandsList[0];
    } else return "none";
  };

  return (
    <div className={classes.showProducts}>
      <section>
        <Subcategory
          onListChange={onListChange}
          onsubListChange={onsubListChange}
          categoryList={listCategory.list}
        ></Subcategory>
      </section>
      <section className={classes.Categories}>
        <Category
          brand={displayedBrand}
          title={listCategory.title}
          list={displayedList}
        ></Category>
      </section>
    </div>
  );
};

export default SurfaceProducts;
