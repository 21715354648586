import menubackground from "../../images/productsImages/tankImages/water.jpg";
import fiberglassTank from "../../images/productsImages/tankImages/fiberglassTank.jpg";
import arvek from "../../images/productsImages/tankImages/ARVEK.png";
import fpshorizontal from "../../images/productsImages/tankImages/fps.png";
import gwshorizontal from "../../images/productsImages/tankImages/GWSPWN.jpg";
import fps from "../../images/productsImages/tankImages/FPS.jpg";
import gwschallenger from "../../images/productsImages/tankImages/CHALLENGER.jpg";
import gwspwn from "../../images/productsImages/tankImages/PWN.jpg";
import sptbvertical from "../../images/productsImages/tankImages/tanqueSPTBvert.jpg";
import sptbhorizontal from "../../images/productsImages/tankImages/tanqueMSPTN.jpg";
import msptnvertical from "../../images/productsImages/tankImages/tanqueMSPTNvert.jpg";
import thl from "../../images/productsImages/tankImages/serieTHL.jpg";
import tvl from "../../images/productsImages/tankImages/tanqueTVL.jpg";
import fiberglassIcon from "../../images/productsImages/tankImages/fiberglassIcon.svg";
import verticalIcon from "../../images/productsImages/tankImages/verticalIcon.svg";
import horizontalIcon from "../../images/productsImages/tankImages/horizontalIcon.svg";

import pdf1 from "../../pdf/tank/fiberglass.pdf";
import pdf2 from "../../pdf/tank/TANKARVEK.pdf";
import pdf3 from "../../pdf/tank/FPShorizontal.pdf";
import pdf4 from "../../pdf/tank/PressureWave_ES.pdf";
import pdf5 from "../../pdf/tank/SPTB.pdf";
import pdf6 from "../../pdf/tank/MSPTB.pdf";
import pdf7 from "../../pdf/tank/FPSvertical.pdf";
import pdf8 from "../../pdf/tank/challenger.pdf";
import pdf9 from "../../pdf/tank/PressureWave_ES.pdf";
import pdf10 from "../../pdf/tank/sistema.pdf";

const fiberglassList = [
  {
    _id: "tanFib001",
    image: fiberglassTank,
    title: "C2LITE CAD",
    brand: "Globalwater solutions",
    code: "C2LITE CAD",
    downLoad: pdf1,
  },
];
const horizontalMetallicList = [
  {
    _id: "tanHor001",
    image: arvek,
    title: "Tanque horizontal",
    brand: "ARVEK",
    code: "C2LITE CAD",
    downLoad: pdf2,
  },
  {
    _id: "tanHor002",
    image: fpshorizontal,
    title: "Tanque hidroneumático horizontal FPS",
    brand: "Franklin Electric",
    code: "FPS",
    downLoad: pdf3,
  },
  {
    _id: "tanHor003",
    image: gwshorizontal,
    title: "Tanque hidroneumático horizontal PWN",
    brand: "Globalwater solutions",
    code: "GWS PWN",
    downLoad: pdf4,
  },
  {
    _id: "tanHor004",
    image: sptbhorizontal,
    title: "Tanque hidroneumático horizontal serie SPTB, SPTT, SPTN",
    brand: "Speroni",
    code: "SPTB",
    downLoad: pdf5,
  },
  {
    _id: "tanHor005",
    image: thl,
    title: "Tanque hidroneumático horizontal serie THL",
    brand: "Sistema",
    code: "THL",
    downLoad: pdf10,
  },
];

const verticalMetallicList = [
  {
    _id: "tanVer001",
    image: fps,
    title: "Tanque hidroneumático vertical FPS",
    brand: "Franklin Electric",
    code: "FPS",
    downLoad: pdf7,
  },
  {
    _id: "tanVer002",
    image: gwschallenger,
    title: "Tanque hidroneumático vertical Challenger",
    brand: "Globalwater solutions",
    code: "GWS CHALLENGER",
    downLoad: pdf8,
  },
  {
    _id: "tanVer003",
    image: gwspwn,
    title: "Tanque hidroneumático vertical PWN",
    brand: "Globalwater solutions",
    code: "GWS PWN",
    downLoad: pdf9,
  },
  {
    _id: "tanVer004",
    image: sptbvertical,
    title: "Tanque hidroneumático vertical SPTB, SPTT, SPTN",
    brand: "Speroni",
    code: "SPTT",
    downLoad: pdf5,
  },
  {
    _id: "tanVer005",
    image: msptnvertical,
    title: "Tanque hidroneumático vertical MSPTB, MSPTT, MSPTN",
    brand: "Speroni",
    code: "MSPTT",
    downLoad: pdf6,
  },
  {
    _id: "tanVer006",
    image: tvl,
    title: "Tanque hidroneumático vertical TVL",
    brand: "Sistema",
    code: "TVL",
    downLoad: pdf10,
  },
];

const tankList = [
  {
    _id: "tan001",
    image: menubackground,
    title: "Tanques Fibra de Vidrio",
    link: "/productos/tanqueshidroneumaticos/fibradevidrio",
    list: fiberglassList,
    path: "fibradevidrio",
    icon: fiberglassIcon,
  },
  {
    _id: "tan002",
    image: menubackground,
    title: "Metálicos Horizontales",
    link: "/productos/tanqueshidroneumaticos/tanquehorizontalmetalico",
    list: horizontalMetallicList,
    path: "tanquehorizontalmetalico",
    icon: horizontalIcon,
  },
  {
    _id: "tan003",
    image: menubackground,
    title: "Metálicos Verticales",
    link: "/productos/tanqueshidroneumaticos/tanqueverticalmetalico",
    list: verticalMetallicList,
    path: "tanqueverticalmetalico",
    icon: verticalIcon,
  },
];

export default tankList;
